<template>
  <div>
    <portal to="subheader_actions">
      <b-button @click="$router.push({ name: 'project_wizard' })" class="mr-2">
        <span class="svg-icon svg-icon-primary">
          <inline-svg src="media/svg/icons/Navigation/Plus.svg" />
        </span>
        Nuevo
      </b-button>
      <b-button class="mr-2">
        <span class="svg-icon svg-icon-primary">
          <inline-svg src="media/svg/icons/Files/Export.svg" />
        </span>
        Exportar
      </b-button>
      <b-button class="mr-2">
        <span class="svg-icon svg-icon-primary">
          <inline-svg src="media/svg/icons/Text/Bullet-list.svg" />
        </span>
        Listar Todo
      </b-button>
      <b-button class="mr-2">
        <span class="svg-icon svg-icon-primary">
          <inline-svg src="media/svg/icons/Text/Filter.svg" />
        </span>
        Filtrar
      </b-button>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Lista de Proyectos</h6>
          </template>
          <b-card-text>
            <div>
              <b-table
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(status)="data">
                  {{ calculateStatus(data.item.is_active) }}
                </template>

                <template #cell(actions)="data">
                  <div class="h5 mb-0">
                    <b-button
                      size="sm"
                      variant="light"
                      @click="
                        $router.push({
                          name: 'project_edit',
                          params: { id: data.item.id }
                        })
                      "
                    >
                      <i class="fa fa-edit" />
                    </b-button>
                    <b-button size="sm" variant="light">
                      <i class="fa fa-eye" />
                    </b-button>
                    <b-button
                      size="sm"
                      variant="light"
                      @click="deleteItem(data.item.id)"
                    >
                      <i class="far fa-trash-alt" />
                    </b-button>
                    <b-button size="sm" variant="light">
                      <i class="fa fa-cog" />
                    </b-button>
                    <b-button size="sm" variant="light">
                      <i class="far fa-clock" />
                    </b-button>
                    <b-button size="sm" variant="light">
                      <i class="fa fa-user-cog" />
                    </b-button>
                    <b-button size="sm" variant="light">
                      <i class="fa fa-paper-plane" />
                    </b-button>
                    <b-button size="sm" variant="light">
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<style scoped>
#kt_content > #kt_subheader {
  display: none;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      form: { category: null },
      categories: [],
      clients: [],
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "actions",
          label: "Acciones"
        },
        {
          key: "code",
          sortable: true,
          label: "Código"
        },
        {
          key: "abbrev",
          sortable: true,
          label: "Abreviatura"
        },
        {
          key: "description",
          sortable: true,
          label: "Descripción"
        },
        {
          key: "client",
          sortable: true,
          label: "Cliente"
        },
        {
          key: "manager",
          sortable: true,
          label: "Responsable"
        },
        {
          key: "status",
          sortable: true,
          label: "Estado"
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    deleteItem(id) {
      ApiService.delete(`api/project/${id}`).then((response) => {
        this.categories = response.data;
        this.listItems();
      });
    },
    calculateStatus(status) {
      return status === true ? "ACTIVO" : "INACTIVO";
    },
    chargeSelects() {
      ApiService.get("api/category", "").then((response) => {
        this.categories = response.data;
      });
      ApiService.get("api/client", "").then((response) => {
        this.clients = response.data;
      });
    },
    saveItem() {
      ApiService.post("api/project/", this.form)
        .then(() => {
          this.modalShow = false;
          this.$bvToast.toast("Se ha creado el item correctamente", {
            title: `Éxito`,
            variant: "success",
            solid: true
          });
          this.listItems();
        })
        .catch(() => {
          this.$bvToast.toast("Ha ocurrido un error", {
            title: `Error`,
            variant: "danger",
            solid: true
          });
        });
    },
    listItems() {
      ApiService.get("api/project", "").then((response) => {
        this.items = response.data;
      });
    }
  },
  created() {
    this.listItems();
    this.chargeSelects();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ingeniería" },
      { title: "Proyectos" }
    ]);
  }
};
</script>
